import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../AboutPage.css';
import image1 from '../assets/Images/bala-1.jpeg'
import image2 from '../assets/Images/Muzi-1.jpg'
import aboutimage1 from '../assets/Images/aboutimage-1.jpg'
import aboutimage2 from '../assets/Images/aboutimage-2.jpg'
import aboutimage3 from '../assets/Images/aboutimage-3.jpg'
import aboutgraph from '../assets/Images/aboutgraph.png'
import { Link } from 'react-router-dom';


function AboutPage() {
  const [expanded, setExpanded] = useState({});
  const toggleReadMore = (index) => {
    setExpanded((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const imageData = [
    {
      img: aboutimage1,
      title: 'Strategic Financial Solutions for Business Growth',
      description: [
        'We are now part of a profit technology concierge that charges no money upfront.',
        'We bring with us 40 centers of expertise in various aspects of business to drive profitability and sales.',
        'We find money and sales opportunities in business at a cost of pennies to the dollars found based on the review and acceptance by the business and its advisors.',
        'Goal is to help our client finance analytics for accelerated innovation and growth using AI technology.'
      ],
    },
    {
      img: aboutimage2,
      title: 'Profit Enhancement Through Strategic Insights',
      description: [
        'Goal is to help our client finance analytics for accelerated innovation and growth using AI technology.',
        'Our partners identify savings in various areas of our client business and get paid a small percentage of the savings or additional revenue or cash flow.',
        'The Analytics and AI subscriptions enable our clients to have even higher future savings based on efficiencies and increased revenue.',
        'We have nearly 25 data products that we are offering and also white-labeling with partners like you.'
      ],
    },
    {
      img: aboutimage3,
      title: 'KrunchIQ Revenue Acceleration Approach',
      description: [
        'Each stack is a custom prescription based on value, priorities, and entitlements peculiar to the business.',
        'Differentiation is information that is not known to other humans or AI.',
        'Done for you at no upfront cost for pennies to the dollar.',
      ],
    },
    {
      img: aboutgraph,
      title: 'Traditional ROI Approach vs Ours',
      description: [
        'Any cost savings on entitlements found are often recurring revenue, and businesses can use those funds to offset costs like client payments or apply them to the development of other internal projects.',
        'Our partners provide an alternative to banks and financing, supplementing profit creation with innovative solutions.',
        'Formulas and Definitions:',
        '• Initial investment: Y(0) = a ⋅ X(0) + X(0)  ≥  0',
        '• Y(0): Net initial investment revenue to the client.',
        '• X(0): Initial generated savings and cash flows.',
        '• Y(t): Net investment revenue for a future period t to the client.',
        '• X(t): Initial (plus additional savings and cash flows if applicable) generated savings and cash flows for the future period t.',
        '• a: Added net value of analytics and AI as a percentage of investment.',
        '• Future AI and analytics cash flows: Y(t) = a ⋅ X(t)  ≥ 0.',
        'If the project is not Win-Win after the initial assessment, we will not pursue it.'
      ],
    },
  ];


  return (
    <div className="about-page container mt-5">
      {/* Section 1: Logo and Navbar */}
      {/* Navbar */}
      <nav className="navbar3 navbar-expand-lg navbar-light bg-transparent">
        <div className="container">
          {/* Navbar Center */}
          <div className="navbar-collapse3 justify-content-center">
            <ul className="navbar-nav3">
              <li className="nav-item3">
                <a className="nav-link3 text-primary fw-bold" href="/">
                  Home
                </a>
              </li>

              <li className="nav-item3">
                <a className="nav-link3 text-primary fw-bold" href="/products">
                  Products
                </a>
              </li>
              <li className="nav-item3">
                <a className="nav-link3 text-primary fw-bold" href="/login">
                  Platform
                </a>
              </li>
              <li className="nav-item3">
                <a className="nav-link3 text-primary fw-bold" href="/about">
                  About Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>


      <div className="title-section text-center mb-5 p-4 rounded shadow">
        <h1 className="main-title">Our Client Delivery Model</h1>
        <p className="subtitle mt-3">
          Team and Execution framework
        </p>
      </div>


      {/* Founder Section */}
      <h2 className="text-center mb-4">Meet our Founders</h2>
      <div className="founders-section">
        <div className="card shadow-lg p-4">
          <img
            src={image2}
            alt="M. Mtetwa"
            className="card-img-top rounded-circle mx-auto d-block"
          />
          <div className="card-body text-center">
            <h5 className="card-title text-primary">M. Mtetwa, PhD</h5>
            <p className="card-text">Co-Founder & Platform Director</p>
            <div className="social-icons d-flex justify-content-center">
              <a href="#" className="me-3 text-decoration-none text-primary">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="#" className="me-3 text-decoration-none text-primary">
                <i className="bi bi-linkedin"></i>
              </a>
              <a href="#" className="text-decoration-none text-primary">
                <i className="bi bi-twitter"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="card shadow-lg p-4">
          <img
            src={image1}
            alt="Balachandran Kairalisadanam"
            className="card-img-top rounded-circle mx-auto d-block"
          />
          <div className="card-body text-center">
            <h5 className="card-title text-primary">Balachandran Kairalisadanam, PhD</h5>
            <p className="card-text">Co-Founder & CEO</p>
            <div className="social-icons d-flex justify-content-center">
              <a href="#" className="me-3 text-decoration-none text-primary">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="#" className="me-3 text-decoration-none text-primary">
                <i className="bi bi-linkedin"></i>
              </a>
              <a href="#" className="text-decoration-none text-primary">
                <i className="bi bi-twitter"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Innovate Section */}
      <div className="innovate-section text-center mb-5 p-5 rounded shadow" style={{ backgroundColor: '#f0f8ff' }}>
        <h2 className="mb-3 text-primary">Innovate with AI & Multi-Modal Machine Learning</h2>
        <p className="mb-4 text-muted">
          Transform your business with our advanced e-commerce platform powered by AI-driven insights and machine learning analytics, designed to enhance efficiency, optimize decision-making, and drive sustainable growth.
        </p>
        <button className="btn btn-primary btn-lg">Learn More</button>
      </div>

      {/* Updated Image Grid Section */}
      <div className="image-grid-section">
        {imageData.map((item, index) => (
          <div className="image-item row align-items-center mb-4" key={index}>
            {/* Left: Image */}
            <div className="col-md-4">
              <img
                src={item.img}
                alt={item.title}
                className="img-fluid rounded shadow-sm"
              />
            </div>
            {/* Right: Title and Description */}
            <div className="col-md-8 content">
              <h4 className="text-primary">{item.title}</h4>
              {expanded[index] ? (
                <ul className="text-muted">
                  {item.description.map((point, i) => (
                    <li key={i}>{point}</li>
                  ))}
                </ul>
              ) : (
                <p className="text-muted">
                  {item.description[0]}...{' '}
                  <button
                    className="btn btn-link text-primary"
                    onClick={() => toggleReadMore(index)}
                  >
                    Read more
                  </button>
                </p>
              )}
              {expanded[index] && (
                <button
                  className="btn btn-link text-primary"
                  onClick={() => toggleReadMore(index)}
                >
                  Read less
                </button>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Assistance Section */}
      <div className="assistance-section text-center mb-5 p-5 rounded shadow" style={{ backgroundColor: '#e6f7ff' }}>
        <h2 className="mb-3 text-primary">Need assistance?</h2>
        <p className="mb-4 text-muted">
          We are here to help you. Fill out the contact form and let us assist you.
        </p>
        <button
          className="btn btn-primary btn-lg"
          onClick={() => (window.location.href = '/login')}
        >
          Contact Us
        </button>
      </div>

      {/* Footer Section */}
      <footer className="footer bg-dark text-white text-center py-4">
        <p>© 2025 Lambda Crunch Inc. All rights reserved.</p>
        <div className="mt-3">
          <Link to="/terms-of-service" className="text-white me-3">
            Terms of Service
          </Link>
          <Link to="/policy" className="text-white">
            Privacy Policy
          </Link>
        </div>
      </footer>
    </div >
  );
}

export default AboutPage;
