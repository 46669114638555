import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../ProductsPage.css';
import audioFile from '../assets/Audio/Krunchiq-case studies (2).wav';
import { Link } from 'react-router-dom';



function ProductsPage() {
  return (
    <div className="container mt-5">
      {/* Section 1: Title and Navigation Bar */}
      <header className="navbar2 navbar-expand-lg navbar-light bg-light">
        <a className="navbar-brand" href="/" aria-label="Home">Home</a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link" href="#services" aria-label="AI Agent Services Section">AI Agent Services</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#timeline" aria-label="Timeline Section">Timeline</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#audio" aria-label="Audio Section">Podcast</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#appointment" aria-label="Appointment Section">Appointment</a>
            </li>
          </ul>
        </div>
      </header>

      {/* Section 2: Description */}
      <section className="description-section mt-5">
        <h2>AI Agent Services</h2>
        <p>
          Our AI Agents are designed to automate and optimize workflows, delivering
          seamless integration and enhanced efficiency across all domains.
        </p>``
      </section>

      {/* Section 3: Services */}
      <section id="services" className="services-section my-5">
        <h3 className="mb-4 text-center">AI Agents</h3>
        <ul className="list-group">
          <li className="list-group-item">
            <h5>Simscaled AI Agent</h5>
            <p>We offer tailored solutions for Marketing Mix Measurement.
              Optimize your planning with data-driven insights..</p>
          </li>
          <li className="list-group-item">
            <h5>Mixscaled AI Agent</h5>
            <p>We provide ad measurement services, analyzing the contribution of base sales and marketing factors to total demand.
              Deliverables include comprehensive dashboards and data files.</p>
          </li>
          <li className="list-group-item">
            <h5>Recommendscaled AI Agent</h5>
            <p>Our goal is to create top recommendations for all users, delivering personalized suggestions to e-commerce checkouts.
              We also offer market basket analysis via APIs, enabling BI data integration with deliverables in file forma.</p>
          </li>
          <li className="list-group-item">
            <h5>Promoscaled AI Agent</h5>
            <p> Empowering businesses with scalable AI-driven promotional strategies.</p>
          </li>
          <li className="list-group-item">
            <h5>Retainscaled AI Agent
            </h5>
            <p>Develop AI-driven targeting strategies from user sales data, delivering personalized campaigns via channels like Mailchimp or Google Ads</p>
          </li>
          <li className="list-group-item">
            <h5>Attribscaled AI Agent
            </h5>
            <p>Provide multi-touch attribution services with advanced scoring algorithms, measuring digital ad effectiveness using user-level sales and campaign data.</p>
          </li>
          <li className="list-group-item">
            <h5>Acquirescaled AI Agent
            </h5>
            <p>Deliver AI/ML-driven lookalike recommendations and retention insights using behavioral data, providing lead files with identification for targeted outreach.</p>
          </li>
          <li className="list-group-item">
            <h5>Leadscaled AI Agent
            </h5>
            <p>Deliver AI/ML-driven lead qualification, segmentation, and recommendations using 5x5 data, with retention insights and churn patterns, providing lead files with identification</p>
          </li>
          <li className="list-group-item">
            <h5>Demandscaled AI Agent
            </h5>
            <p>Empowering businesses with AI-driven demand forecasting and scalable market insights.</p>
          </li>
          <li className="list-group-item">
            <h5>Churnscaled AI Agent</h5>
            <p>Harness AI to predict churn, retain customers, and drive scalable growth strategies.</p>
          </li>
          <li className="list-group-item">
            <h5>HRscaled AI Agent
            </h5>
            <p> Leveraging AI to optimize talent acquisition, employee retention, and HR operations at scale.</p>
          </li>
          <li className="list-group-item">
            <h5>Sellscaled AI Agent</h5>
            <p>Empowering sales growth with AI-driven strategies and scalable customer engagement solutions..</p>
          </li>
          <li className="list-group-item">
            <h5>Campaignscaled AI Agent
            </h5>
            <p>Driving impactful marketing with AI-powered campaign optimization and scalability</p>
          </li>
          <li className="list-group-item">
            <h5>Performscaled AI Agent</h5>
            <p>Enhancing performance with AI-driven insights and scalable optimization solutions.</p>
          </li>
          <li className="list-group-item">
            <h5>Fraudscaled AI Agent
            </h5>
            <p>Detecting and preventing fraud with AI-powered, scalable solutions.</p>
          </li>
          <li className="list-group-item">
            <h5>Docuscaled AI Agent
            </h5>
            <p>Provide accurate document retrieval and summarization using Large Language Models, extracting knowledge from submitted files into secure Q&A or themed summaries. Deliverable: processed output documents</p>
          </li>
        </ul>
      </section>

      {/* Section 4: Timeline */}
      <section id="timeline" className="timeline-section my-5">
        <h3 className="mb-4">Timeline</h3>
        <ul className="timeline">
          <li>
            <span className="timeline-date">August 2018</span>
            <p>Company founded. Platform development started.</p>
          </li>
          <li>
            <span className="timeline-date">December 2023</span>
            <p>Introduction of platform to beta clients. KrunchIq platform beta testing with clients starts.</p>
          </li>
          <li>
            <span className="timeline-date">June 2024</span>
            <p>First client on-boarded.</p>
          </li>
          <li>
            <span className="timeline-date">August 2024</span>
            <p>KrunchIq is currently powering analytics for 21 e-commerce clients.</p>
          </li>
        </ul>
      </section>

      {/* Section 5: Audio */}
      <section id="audio" className="audio-section my-5 text-center">
        <h3 className="mb-4">Podcast</h3>
        <audio controls>
          <source src={audioFile} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </section>

      {/* Section 6: Description */}
      <section className="next-gen-section text-white text-center p-5">
        <h3 className="mb-3">Next-Generation Data-Driven Digital Transformation</h3>
        <p>
          We are redefining productivity and innovation by integrating advanced artificial intelligence and data analytics into core business processes. Our approach enables businesses to harness the power of machine learning and predictive analytics to process vast datasets, extract actionable insights, and make data-driven decisions in real time. This allows for precise optimization of operations, improved customer experiences, and accelerated market adaptability.

          By automating repetitive and time-intensive tasks, AI enhances human capabilities, enabling teams to focus on strategic thinking, innovation, and creative problem-solving. AI-powered agents act as intelligent tools that streamline workflows, reduce operational bottlenecks, and increase efficiency at scale.

          Our vision is to create a future where humans and AI collaborate seamlessly. This next-generation transformation not only reshapes industries but also empowers people to achieve more, fostering a productive synergy where technology amplifies human ingenuity.
        </p>
      </section>

      {/* Section 7: Appointment */}
      <section id="appointment" className="appointment-section my-5 text-center">
        <h3 className="mb-3">Schedule an Appointment</h3>
        <p>
          Plug in your own data or connect to reputable data sources to start generating leads or getting insights and recommendations for your business.
        </p>
        <button
          className="btn btn-primary"
          onClick={() => window.location.href = 'https://app.krunchiq.com/'}
        >
          Schedule an Appointment
        </button>
      </section>

      {/* Footer Section */}
      <footer className="footer bg-dark text-white text-center py-4">
        <p>© 2025 Lambda Crunch Inc. All rights reserved.</p>
        <div className="mt-3">
          <Link to="/terms-of-service" className="text-white me-3">
            Terms of Service
          </Link>
          <Link to="/policy" className="text-white">
            Privacy Policy
          </Link>
        </div>
      </footer>
    </div>
  );
}

export default ProductsPage;
