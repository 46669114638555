import React from 'react';
import '../Termsofservice.css';
import { useNavigate } from 'react-router-dom';


const Termsofservice = () => {

    const navigate = useNavigate();
    return (
        <div className="terms-container">
            <h1>Terms of Service</h1>

            {/* Section 1: Acceptance of the Agreement */}
            <section>
                <h2>1. ACCEPTANCE OF THE AGREEMENT</h2>
                <p>
                    Lambda Crunch inc. wishes to grant to the Client the license of the right to use and operate a duplicate of the Software and any associated product, feature, software, application, data feed and service (hereinafter collectively referred to as the "LambdaiX Platform") and the Client wishes to obtain such licence from Lambda Crunch inc.
                </p>
                <p>
                    An individual or a legal entity on whose behalf the Client agrees to the terms and conditions of the present LambdaiX Platform License Agreement and to provisions of policies incorporated herein by reference (hereinafter collectively referred to as the "Agreement"). If the Client is accepting the terms and conditions of the present Agreement on behalf of any entity, the Client represents and warrants that it is authorized to accept the Agreement on such entity's behalf, and that such entity agrees to indemnify the Client and Lambda Crunch inc. for violations of the Agreement.
                </p>
                <p>
                    The Agreement is entered into by and between the Client and Lambda Crunch inc.. If the Client is operating the LambdaiX Platform on behalf of any entity, the Client represents and warrants that the Client is authorized to accept the Agreement on such entity's behalf, and that such entity agrees to indemnify the Client and Lambda Crunch inc. for violations of the Agreement.
                </p>
                <p>
                    The Client's operation of the LambdaiX Platform shall be subject to the terms and conditions contained in the Agreement. By operating the LambdaiX Platform, the Client accepts and agrees to be bound by the terms and conditions of the Agreement. If the Client does not agree to be bound by any of the provisions contained in the Agreement, the Client shall not access, use, or operate the LambdaiX Platform.
                </p>
                <p>
                    The Client may not use or operate the LambdaiX Platform and may not enter into the Agreement if (a) the Client is not of legal age to form a binding contract with Lambda Crunch inc., or (b) the Client is a person who is either barred or otherwise legally prohibited from using or operating the LambdaiX Platform under the laws of the country in which the Client is a resident or from which the Client uses or operates the LambdaiX Platform.
                </p>
                <p>
                    The present Agreement does not alter in any way the terms or conditions of any other written agreement the Client may have with Lambda Crunch inc..
                </p>
            </section>

            {/* Section 2: Granted License */}
            <section>
                <h2>2. GRANTED LICENSE</h2>
                <h3>Licence</h3>
                <p>
                    Subject to the terms and conditions of the Agreement, Lambda Crunch inc. grants the Client a worldwide, non–transferable, non–assignable, non–sublicensable and non–exclusive licence of the right to operate the LambdaiX Platform under Client's own brand name.
                </p>
                <h3>General Restrictions</h3>
                <p>
                    The rights granted to the Client in the Agreement are subject to the following restrictions: (a) the Client shall not sublicense, sell, rent, lease, reproduce, copy, transfer, or assign any part of the LambdaiX Platform; (b) the Client shall not modify, translate, adapt, merge, make derivative works of, disassemble, decompile, reverse compile, reverse engineer or otherwise attempt to discover the source code, object code, or underlying structure, ideas, or algorithms of any part of the LambdaiX Platform; (c) the Client shall not use the LambdaiX Platform in order to build a similar or competitive service; (d) except as expressly stated herein, no part of the LambdaiX Platform may be copied, downloaded, and republished in any form or by any means; (e) the Client shall not use, or encourage, promote, facilitate or instruct other users to use, the LambdaiX Platform for any illegal, harmful or offensive use, or to transmit, store, display, distribute or otherwise make available content that is illegal, harmful, or offensive (including content which offends religious sensibilities).
                </p>
                <p>
                    The Client agrees not to use the LambdaiX Platform in order to: (a) upload or distribute any computer viruses, worms, malicious code, or any software intended to damage or alter a computer system or a mobile device or data; (b) collect information or data regarding other users, including e–mail addresses, without their consent (e.g., using harvesting bots, robots, spiders, or scrapers); or (c) disable, overly burden, impair, or otherwise interfere with servers or networks connected to the LambdaiX Platform (e.g., a denial of service attack).
                </p>
                <p>
                    Modification. Lambda Crunch inc. reserves the right, at any time, to modify, suspend, or discontinue the operation of the LambdaiX Platform or any part thereof, with or without notice. The Client agrees that we will not be liable to the Client or to any third party for any modification, suspension, or discontinuance of operation of the LambdaiX Platform or any part thereof.
                </p>
                <p>
                    Ownership. Lambda Crunch inc. and our licensors (where applicable) own all right, title and interest, including all related intellectual property rights, in and to the LambdaiX Platform and content therein. As stated above, the right to use and operate the LambdaiX Platform is licensed to the Client; this means that the LambdaiX Platform is under no circumstances sold/transferred to the Client. Indeed, the Agreement does not convey to the Client any rights of ownership in or related to the LambdaiX Platform. Lambda Crunch inc. name, logo, and other names associated with the LambdaiX Platform belong to Lambda Crunch inc. (or to Lambda Crunch inc.'s licensors, where applicable), and no licence of the right to use them by implication, estoppel or otherwise is granted to the Client hereunder. Lambda Crunch inc. (and Lambda Crunch inc.'s licensors, where applicable) reserve all rights not granted in the Agreement.
                </p>
            </section>

            {/* Section 3: Services Fees, Cancellation and Other Payment Terms */}
            <section>
                <h2>3. SERVICES FEES, CANCELLATION OTHER PAYMENTS TERMS</h2>
                <p>
                    By using the LambdaiX Platform, the Client agrees to pay all associated fees with the Client's licensing of the right to operate the LambdaiX Platform. Lambda Crunch inc. reserves the right to adjust its fee structure at any time. The Client may cancel this service at any time and for any reason, however, all fees are non–refundable. Therefore, a cancellation will only cancel future billings. Lambda Crunch inc. reserves the right to revoke access to the LambdaiX Platform if the applicable fees are not paid when due.
                </p>
                <p>
                    The Client shall be solely responsible for any use of or action taken through the use of such password on the LambdaiX Platform. Lambda Crunch inc. cannot and will not be liable for any loss or damage arising from the Client's failure to comply with this requirement. By using Licensee Account, the Client acknowledges and agrees that Lambda Crunch inc.'s account security procedures are commercially reasonable. Lambda Crunch inc. reserves the right to revoke the Client's right to access Licensee Account at any time if any misuse of the LambdaiX Platform occurs, or if unauthorized activity is detected in the system.
                </p>
            </section>

            {/* Section 4: Licensee Account */}
            <section>
                <h2>4. LICENSEE ACCOUNT</h2>
                <p>
                    In order to be able to operate the LambdaiX Platform, the Client will need to create a licensee account ("Licensee Account") and provide certain information as requested by Lambda Crunch inc. in the online registration form. The Client represents and warrants that (a) all required registration information submitted is truthful and accurate, (b) the Client will maintain the accuracy of such information, and (c) the Client's use of the LambdaiX Platform does not violate any applicable laws.
                </p>
                <p>
                    The Client shall be responsible for maintaining and ensuring the confidentiality and security of the Licensee Account's login information, and for all activities that occur under the Licensee Account. The Client agrees not to disclose the password to any third party.
                </p>
            </section>

            {/* Section 5: The Client's Obligations */}
            <section>
                <h2>5. THE CLIENT'S OBLIGATIONS</h2>
                <p>As a LambdaiX Platform licensee, the Client hereby agrees that the Client shall:</p>
                <ol>
                    <li>
                        Be solely responsible for all the Client's trademarks, logos, design, media, text, graphics, animations, audio, video, photos or any other information posted and published on the LambdaiX Platform and for activity that occurs on the LambdaiX Platform.
                    </li>
                    <li>
                        Be solely responsible for all costs and expenses incurred in connection with the operation of the LambdaiX Platform.
                    </li>
                    <li>
                        Be solely responsible for all tax returns, contributions and payments required to be filed with or made to any federal, state, or local tax authority, in the Client's country of residence, with respect to the Client's operation of the LambdaiX Platform.
                    </li>
                    <li>
                        Not use the LambdaiX Platform for any illegal or unauthorized purposes. The Client must not, in the operation of the LambdaiX Platform, violate any laws applicable to the Client's or Lambda Crunch inc.'s jurisdictions (including but not limited to copyright laws).
                    </li>
                    <li>
                        Use good, fair and honest business practices in connection with the operation of the LambdaiX Platform.
                    </li>
                    <li>
                        Not take any action that imposes or may impose (as determined by Lambda Crunch inc. in its sole discretion) an unreasonable or disproportionately large load on Lambda Crunch inc.'s infrastructure.
                    </li>
                </ol>
            </section>

            {/* Section 6: Privacy Policy */}
            <section>
                <h2>6. PRIVACY POLICY</h2>
                <p>
                    Please refer to Lambda Crunch inc.'s Privacy Policy for information on how we collect, use and disclose personally identifiable information.
                </p>
            </section>

            {/* Section 7: Term and Termination */}
            <section>
                <h2>7. TERM AND TERMINATION</h2>
                <p>
                    The Client hereby acknowledges and agrees that the Agreement shall come into force on the date of the first use of the LambdaiX Platform and accepts the provisions contained in the Agreement, and will remain in force and effect until terminated in accordance with the Agreement. Either Lambda Crunch inc. or the Client may terminate this Agreement for convenience with at least 30 (thirty) days prior written notice (to include e–mail). Lambda Crunch inc. may (a) suspend the Client's rights to use and operate the LambdaiX Platform, and/or (b) terminate the Agreement, at any time at Lambda Crunch inc.'s sole discretion with or without notice to the Client, if Lambda Crunch inc. believes the Client has violated any provision of the Agreement.
                </p>
                <p>
                    Upon termination of the Agreement, the Client's right to use the LambdaiX Platform will automatically and immediately terminate. The Client understands that any termination of the Agreement may involve deletion of the Client's content from the LambdaiX Platform and Lambda Crunch inc.'s databases.
                </p>
                <p>
                    Any termination of this Agreement (howsoever occasioned) shall not affect any accrued legal rights, obligations, and liabilities of either the Client or Lambda Crunch inc., nor shall it affect the coming into force or the continuance in force of any provision hereof which is expressly or by implication intended to come into or continue in force on or after any such termination.
                </p>
            </section>

            {/* Section 8: Indemnification */}
            <section>
                <h2>8. INDEMNIFICATION</h2>
                <p>
                    The Client agrees to defend, indemnify and hold harmless Lambda Crunch inc., its directors, employees, agents, independent contractors, service providers and consultants, from and against any claims, damages, costs, liabilities and expenses (including, but not limited to, reasonable attorneys' fees) arising out of or related to any part of the content the Client provides to Lambda Crunch inc. or posts to, stores or otherwise publishes on the LambdaiX Platform, or the Client's use of or inability to use the LambdaiX Platform, including without limitation any actual or threatened suit, demand or claim made against Lambda Crunch inc. and/or its directors, employees, agents, independent contractors, service providers and consultants, arising out of or relating to (a) the Client's partners' and customers' content or conduct, (b) the Client's violation of the Agreement, or (c) the Client's violation of the rights of any third party.
                </p>
            </section>

            {/* Section 9: Disclaimers */}
            <section>
                <h2>9. DISCLAIMERS</h2>
                <p>
                    EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN WRITING BY Lambda Crunch inc., THE LambdaiX Platform IS PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. THE CLIENT ACKNOWLEDGES AND UNDERSTANDS THAT Lambda Crunch inc. USES THIRD PARTY VENDORS AND HOSTING PARTNERS TO PROVIDE THE NECESSARY HARDWARE, SOFTWARE, NETWORKING, STORAGE, AND RELATED TECHNOLOGY REQUIRED TO RUN THE LambdaiX Platform. Lambda Crunch inc. DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON–INFRINGEMENT AS TO THE LambdaiX Platform, and DOES NOT REPRESENT OR WARRANT THAT CONTENT FOUND ON THE LambdaiX Platform IS ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR–FREE OR THAT THE LambdaiX Platform IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. Lambda Crunch inc. SHALL NOT BE RESPONSIBLE FOR TYPOGRAPHICAL ERRORS OR OMISSIONS FOUND ON THE LambdaiX Platform.
                </p>
            </section>

            {/* Section 10: Limitation of Liability */}
            <section>
                <h2>10. LIMITATION OF LIABILITY</h2>
                <p>
                    IN NO EVENT SHALL Lambda Crunch inc., ITS AFFILIATES, DIRECTORS, EMPLOYEES OR AGENTS BE LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS OR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF THE LambdaiX Platform OR OF CONTENT OWNED BY ANY LambdaiX Platform USER, INCLUDING WITHOUT LIMITATION ANY DAMAGES CAUSED BY OR RESULTING FROM RELIANCE BY THE CLIENT ON ANY INFORMATION OBTAINED FROM Lambda Crunch inc. OR THE LambdaiX Platform, OR THAT RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES OR EMAIL, ERRORS, DEFECTS, VIRUSES, DELAYS IN OPERATION OR TRANSMISSION OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS OF GOD, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO THE LambdaiX Platform. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF Lambda Crunch inc., ITS DIRECTORS, EMPLOYEES OR AGENTS, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY, ARISING OUT OF OR RELATING TO THE USE OF THE LambdaiX Platform, EXCEED ANY COMPENSATION THE CLIENT PAYS, IF ANY, TO Lambda Crunch inc. TO OPERATE THE LambdaiX Platform UNDER THE CLIENT'S OWN BRAND NAME.
                </p>
            </section>

            {/* Section 11: General Provisions */}
            <section>
                <h2>11. GENERAL PROVISIONS</h2>
                <p>
                    Entire Agreement. The Agreement, as well as any policies incorporated herein by reference, constitute the entire agreement between the Client and Lambda Crunch inc. with respect to the subject matters hereof and supersede all prior discussions and agreements (whether in writing or oral) between the Client and Lambda Crunch inc. with respect to such subject matters (including any prior license agreements or terms of service).
                </p>
                <p>
                    Changes to This Agreement. Lambda Crunch inc. reserves the right to change this Agreement as Lambda Crunch inc. may deem necessary from time to time or as may be required by law.
                </p>
                <p>
                    Contractual Relationship. This Agreement does not create a partnership, agency, or employer–employee, or similar relationship between Lambda Crunch inc. and the Client. The Client does not have authority to enter into written or oral – whether implied or express – contracts on behalf of Lambda Crunch inc.. The Client acknowledges that Lambda Crunch inc. does not, in any way, supervise, direct, or control the Client's actions or operation of the LambdaiX Platform.
                </p>
                <p>
                    Waiver. Lambda Crunch inc.'s failure to exercise or enforce any right or provision of the Agreement shall not operate as a waiver of such right or provision.
                </p>
                <p>
                    Assignment. Lambda Crunch inc. may assign, transfer or otherwise dispose of the Agreement in whole or in part or any of our rights hereunder in connection with a merger, acquisition, reorganization or sale of all or substantially all of Lambda Crunch inc.'s assets, or other operation of law, without the Client's consent. The terms and conditions of the Agreement shall be binding upon assignees.
                </p>
                <p>
                    Severability. If any provision of the Agreement is, for any reason, held to be invalid or unenforceable, (i) the other provisions of the Agreement will be unimpaired, and (ii) the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law.
                </p>
                <p>
                    Notices: Consent to Electronic Notice. The Client consents to the use of (a) electronic means to execute this Agreement and to deliver any notices pursuant to this Agreement; and (b) electronic records to store information related to this Agreement or the Client's operation of the LambdaiX Platform. Notices hereunder shall be invalid unless made in writing and given (a) by Lambda Crunch inc. via email (to the email address that the Client provides), or (b) by the Client via email to email addresses as Lambda Crunch inc. may specify in writing. The date of receipt will be deemed the date on which such notice is transmitted.
                </p>
                <p>
                    Applicable Law and Venue. The Agreement and the Client's use of the LambdaiX Platform shall be governed by and construed in accordance with the laws of the State of New Jersey, USA without giving effect to any conflict of laws principles that may require the application of the law of another jurisdiction.
                </p>
                <p>
                    Should a dispute arise between the Client and Lambda Crunch inc., Lambda Crunch inc.'s goal is to provide the Client with a neutral, amicable, quick and cost–effective means of resolving any dispute. Accordingly, the Client and Lambda Crunch inc. hereby agree that the Client and Lambda Crunch inc. shall first attempt to resolve any claim, dispute or controversy at law or equity that arises out of this Agreement in an amicable way prior to submitting any such claim, dispute or controversy to the jurisdiction of courts.
                </p>
            </section>

            {/* Return Button */}
            <div className="return-button-container">
                <button onClick={() => navigate('/')} className="return-button">
                    Return back to website
                </button>
            </div>


        </div>
    );
};

export default Termsofservice;
