import { useEffect } from 'react';

const LoginPage = () => {
    useEffect(() => {
        // Redirect user to the desired URL
        window.location.href = 'https://app.krunchiq.com/';
    }, []);

    return null; // The component renders nothing
};

export default LoginPage;