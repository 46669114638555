import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../HomePage.css';
import logo from '../assets/Images/logo-transparent-png-1.png';
import { Link } from 'react-router-dom';

function HomePage() {
  return (
    <div className="homepage">
      {/* Navbar */}
      <nav className="navbar3 navbar-expand-lg navbar-light bg-transparent">
        <div className="container">

          <a className="navbar-brand logo-container" href="/">
            <img src={logo} alt="KrunchIQAI Logo" className="logo" />
          </a>


          {/* Navbar Center */}
          <div className="navbar-collapse3 justify-content-center">
            <ul className="navbar-nav3">
              <li className="nav-item3">
                <a className="nav-link3 text-primary fw-bold" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item3">
                <a className="nav-link3 text-primary fw-bold" href="/products">
                  Products
                </a>
              </li>
              <li className="nav-item3">
                <a className="nav-link3 text-primary fw-bold" href="/login">
                  Platform
                </a>
              </li>
              <li className="nav-item3">
                <a className="nav-link3 text-primary fw-bold" href="/about">
                  About Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>


      {/* Title Section */}
      <header className="title3-section mb-5 mt-5">
        <div className="container section3-container">
          <div className="title3-wrapper">
            <h1 className="maintitle3">
              Discover, connect with, and engage AI agents to grow your business sustainably.
            </h1>
            <p className="subtitle3">
              Pioneering AI-Driven Strategies for Business Transformation
            </p>
          </div>
        </div>
      </header>

      {/* Cards Section */}
      <div className="row mt-5">
        {/* Card 1 */}
        <div className="col-md-4 feature-card">
          <div className="card shadow">
            <img
              src={require('../assets/Images/5624023.png')}
              alt="Card 1 Icon"
              className="card-icon"
            />
            <h3 className="feature-title mt-3">Meet our Analytic AI Agents</h3>
            <p>
              Our advanced AI agents are designed to analyze, predict, and provide actionable insights to drive smarter decisions. Leveraging state-of-the-art machine learning, they adapt to your unique needs for enhanced efficiency and precision
            </p>
          </div>
        </div>
        {/* Card 2 */}
        <div className="col-md-4 feature-card">
          <div className="card shadow">
            <img
              src={require('../assets/Images/Premium-rating-star-icon-Graphics-13920056-1-1-580x386.jpg')}
              alt="Card 2 Icon"
              className="card-icon"
            />
            <h3 className="feature-title mt-3">Subscribe to Insights and Recommendations</h3>
            <p>
              Generate leads using external databases and receive AI-powered
              lead scoring and qualification recommendations.
            </p>
          </div>
        </div>
        {/* Card 3 */}
        <div className="col-md-4 feature-card">
          <div className="card shadow">
            <img
              src={require('../assets/Images/6622877.png')}
              alt="Card 3 Icon"
              className="card-icon"
            />
            <h3 className="feature-title mt-3">Connect Your E-Commerce Data</h3>
            <p>
              Integrate platforms like Shopify, BigCommerce, or WooCommerce to
              gain actionable insights from your store's data.
            </p>
          </div>
        </div>
      </div>


      {/* Section 3: Features */}
      <div className="features container mt-5">
        <div className="image-section">
          {/* Image 1 */}
          <img
            src={require('../assets/Images/image-2.png')}
            alt="Feature Highlight 1"
            className="feature-image"
          />
          {/* Image 2 */}
          <img
            src={require('../assets/Images/image-3.png')}
            alt="Feature Highlight 2"
            className="feature-image"
          />
          {/* Image 3 */}
          <img
            src={require('../assets/Images/image-5.png')}
            alt="Feature Highlight 3"
            className="feature-image"
          />
          {/* Image 4 */}
          <img
            src={require('../assets/Images/image-4.png')}
            alt="Feature Highlight 4"
            className="feature-image"
          />
        </div>
      </div>

      {/* Section 4: Description */}
      <div className="description-section mt-5">
        <h2>Features of the Simscaled Dashboard</h2>
        <div className="description-box">
          <i className="description-icon bi bi-person-check"></i>
          <p className="description-text">
            <strong>User Authentication and Personalization:</strong> A dedicated login section allows users to securely access the dashboard. Displays the logged-in user's name. Easy "Sign Out" button for user account management.
          </p>
        </div>
        <div className="description-box">
          <i className="description-icon bi bi-layout-text-sidebar-reverse"></i>
          <p className="description-text">
            <strong>Dashboard Manager:</strong> Provides a dropdown to switch between different dashboards, enabling companies to monitor multiple projects or datasets. Easily customizable to suit various organizational needs.
          </p>
        </div>
        <div className="description-box">
          <i className="description-icon bi bi-funnel"></i>
          <p className="description-text">
            <strong>Advanced Data Filtering:</strong> Multiple dropdown menus allow users to filter data by Date Version (e.g., "2023_10_05"), Country (e.g., "UK"), and Product Data (e.g., "Laptop"), enabling precise data segmentation.
          </p>
        </div>
        <div className="description-box">
          <i className="description-icon bi bi-bar-chart-line"></i>
          <p className="description-text">
            <strong>Key Metrics Display:</strong> Highlights the most important business metrics in interactive cards, including Incremental Volume, Total Revenue, Channel Spend, and Total ROI, with mini-graphs providing quick visual snapshots.
          </p>
        </div>
        <div className="description-box">
          <i className="description-icon bi bi-graph-up-arrow"></i>
          <p className="description-text">
            <strong>Revenue Trend Visualization:</strong> A line chart displays revenue trends over time for various marketing channels, helping businesses identify patterns and refine strategies.
          </p>
        </div>
        <div className="description-box">
          <i className="description-icon bi bi-pie-chart"></i>
          <p className="description-text">
            <strong>Revenue Distribution Insights:</strong> A pie chart visually represents the proportion of revenue contributed by each channel, making data interpretation easy.
          </p>
        </div>
        <div className="description-box">
          <i className="description-icon bi bi-pie-chart"></i>
          <p className="description-text">
            <strong>Clean and User-Friendly Design:</strong> Minimalistic layout with intuitive navigation, soft shadows, and rounded edges for a modern look.
          </p>
        </div>
        <div className="description-box">
          <i className="description-icon bi bi-pie-chart"></i>
          <p className="description-text">
            <strong>Actionable Insights for Decision-Making</strong> The dashboard provides actionable data to optimize spending, enhance marketing performance, and maximize ROI.
          </p>
        </div>
      </div>


      {/* Section 4: Lambda Crunch */}
      <section className="lambda-section text-center py-5 mb-5">
        <h2 className="text-white">Lambda Crunch – Where Innovation Meets Application</h2>
        <p className="text-white mt-3">
          Join us in revolutionizing the future of AI, ML, and Data Science
        </p>
        <button
          className="btn btn-light mt-3"
          onClick={() => window.location.href = 'https://app.krunchiq.com/'}
        >
          Get Started Now
        </button>
      </section>

      {/* Section 6: Testimonials */}
      <section className="testimonials bg-light py-5">
        <div className="container text-center">
          <h3 className="mb-4">What Our Clients Say</h3>
          <div className="row g-4">
            {[
              {
                quote:
                  "My experience with KrunchIQ has been great! We've deployed multiple business services based on the solutions provided.",
                author: 'Olivier Benoit / ABKA SARL',
                image: require('../assets/Images/image-3.png'),
              },
              {
                quote:
                  'KrunchIQAI offers exceptional services that helped optimize our operational workflows.',
                author: 'Sophia Martinez / Tech Innovators',
                image: require('../assets/Images/image-4.png'),
              },
              {
                quote:
                  'The insights provided were game-changing for our business operations.',
                author: 'Hiroshi Tanaka / BizPro Solutions',
                image: require('../assets/Images/image-5.png'),
              },
              {
                quote:
                  'KrunchIQAI is a trusted partner for delivering AI-driven insights.',
                author: 'Emma White / DataGenix',
                image: require('../assets/Images/image-6.png'),
              },
              {
                quote:
                  'Exceptional service and support from the KrunchIQAI team.',
                author: 'Rajat Singh / TechWave',
                image: require('../assets/Images/image-7.png'),
              },
            ].map((testimonial, index) => (
              <div key={index} className="col-md-6 col-lg-4">
                <div className="card shadow-sm p-4 rounded testimonial-card">
                  <img
                    src={testimonial.image}
                    alt={`Testimonial ${index + 1}`}
                    className="testimonial-image mb-3"
                  />
                  <blockquote className="blockquote">
                    <p>{testimonial.quote}</p>
                    <footer className="blockquote-footer">{testimonial.author}</footer>
                  </blockquote>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <hr className="section-divider" />

      {/* Section 7: Appointment */}
      <section id="appointment" className="appointment-section my-5 text-center">
        <h3 className="mb-3">Contact KrunchIQ</h3>
        <p>
          Plug in your own data or connect to reputable data sources to start generating leads or getting insights and recommendations for your business.
        </p>
        <button
          className="btn btn-primary"
          onClick={() => window.location.href = 'mailto:support@lambdacrunch.com'}
        >
          Schedule an Appointment
        </button>
      </section>


      {/* Footer Section */}
      <footer className="footer bg-dark text-white text-center py-4">
        <p>© 2025 Lambda Crunch Inc. All rights reserved.</p>
        <div className="mt-3">
          <Link to="/terms-of-service" className="text-white me-3">
            Terms of Service
          </Link>
          <Link to="/policy" className="text-white">
            Privacy Policy
          </Link>
        </div>
      </footer>
    </div >
  );
}

export default HomePage;
