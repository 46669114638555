import React from 'react';
import '../policy.css';

import { useNavigate } from 'react-router-dom';


const Policy = () => {

    const navigate = useNavigate();

    
    return (
        <div className="policy-container">
            <h1>Privacy Policy</h1>
            <p><strong>Effective Date:</strong> 8/24/2024</p>
            <p><strong>Last Updated:</strong> 8/24/2024</p>
            <p>
                Welcome to e-mall247.com, a global e-commerce marketplace platform operated by KrunchIQ. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you use our platform.
            </p>

            {/* Section 1 */}
            <section>
                <h2>1. Scope of the Privacy Policy</h2>
                <p>
                    This Privacy Policy applies to all users of e-mall247.com, including visitors, registered users, sellers, and buyers. It covers our collection, use, and disclosure of your information in connection with your use of our platform and services.
                </p>
            </section>

            {/* Section 2 */}
            <section>
                <h2>2. Information We Collect</h2>
                <h3>2.1. Information You Provide to Us</h3>
                <ul>
                    <li>
                        <strong>Account Information:</strong> When you register for an account, we collect your name, email address, phone number, and other contact information.
                    </li>
                    <li>
                        <strong>Transaction Information:</strong> When you buy or sell products on our platform, we collect information related to your transactions, including payment information, billing and shipping details, and purchase history.
                    </li>
                    <li>
                        <strong>Communication Information:</strong> If you contact us directly, we may receive additional information about you, such as the content of your messages, attachments, and any other information you choose to provide.
                    </li>
                </ul>

                <h3>2.2. Information We Collect Automatically</h3>
                <ul>
                    <li>
                        <strong>Device Information:</strong> We collect information about the devices you use to access our platform, including your IP address, browser type, operating system, and device identifiers.
                    </li>
                    <li>
                        <strong>Usage Data:</strong> We collect information about how you interact with our platform, including the pages you visit, the features you use, and the time you spend on our platform.
                    </li>
                    <li>
                        <strong>Cookies and Tracking Technologies:</strong> We use cookies, web beacons, and similar tracking technologies to collect information about your browsing behavior and improve your user experience. You can control the use of cookies through your browser settings.
                    </li>
                </ul>

                <h3>2.3. Information from Third Parties</h3>
                <ul>
                    <li>
                        <strong>Payment Processors:</strong> We may receive information about your transactions from our third-party payment processors, including details about your purchases and payment methods.
                    </li>
                    <li>
                        <strong>Social Media:</strong> If you choose to link your social media accounts to your account on our platform, we may receive information from those accounts, such as your profile information and activity.
                    </li>
                </ul>
            </section>

            {/* Section 3 */}
            <section>
                <h2>3. How We Use Your Information</h2>
                <h3>3.1. Providing and Improving Our Services</h3>
                <ul>
                    <li>To create and manage your account</li>
                    <li>To process and fulfill your orders</li>
                    <li>To facilitate communication between buyers and sellers</li>
                    <li>To improve and personalize your experience on our platform</li>
                    <li>To develop new features and services</li>
                </ul>

                <h3>3.2. Marketing and Advertising</h3>
                <ul>
                    <li>To send you promotional materials and newsletters (with your consent where required)</li>
                    <li>To display targeted advertising based on your browsing behavior</li>
                    <li>To conduct contests, promotions, and surveys</li>
                </ul>

                <h3>3.3. Legal and Compliance</h3>
                <ul>
                    <li>To comply with applicable laws and regulations, including GDPR and CCPA</li>
                    <li>To respond to legal requests and prevent fraud</li>
                    <li>To enforce our Terms of Use and other agreements</li>
                </ul>
            </section>

            {/* Section 4 */}
            <section>
                <h2>4. Legal Basis for Processing Personal Information</h2>
                <p>We process your personal information based on the following legal grounds:</p>
                <ul>
                    <li>
                        <strong>Consent:</strong> We process your personal information with your consent, which you can withdraw at any time.
                    </li>
                    <li>
                        <strong>Contractual Obligations:</strong> We process your personal information to fulfill our contractual obligations to you, such as processing your orders and providing customer support.
                    </li>
                    <li>
                        <strong>Legitimate Interests:</strong> We process your personal information for our legitimate business interests, such as improving our services, securing our platform, and conducting marketing activities.
                    </li>
                    <li>
                        <strong>Legal Compliance:</strong> We process your personal information to comply with our legal obligations, such as maintaining records for tax purposes and responding to legal requests.
                    </li>
                </ul>
            </section>

            {/* Section 5 */}
            <section>
                <h2>5. Disclosure of Your Information</h2>
                <h3>5.1. Service Providers</h3>
                <p>
                    We share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, email delivery, and hosting services.
                </p>
                <h3>5.2. Business Transfers</h3>
                <p>
                    In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred to the acquiring entity.
                </p>
                <h3>5.3. Legal Obligations</h3>
                <p>
                    We may disclose your personal information if required to do so by law or in response to a legal request, such as a subpoena, court order, or government investigation.
                </p>
                <h3>5.4. Protecting Our Rights</h3>
                <p>
                    We may disclose your personal information to enforce our Terms of Use, protect the security of our platform, and prevent harm to our users or others.
                </p>
            </section>

            {/* Section 6 */}
            <section>
                <h2>6. International Data Transfers</h2>
                <p>
                    As a global platform, e-mall247.com may transfer your personal information to countries outside of your own, including to the United States where our servers are located. We take appropriate measures to ensure that your personal information is protected in accordance with applicable laws, including GDPR and CCPA.
                </p>
            </section>

            {/* Section 7 */}
            <section>
                <h2>7. Your Privacy Rights</h2>
                <h3>7.1. European Union (GDPR)</h3>
                <ul>
                    <li><strong>Right to Access:</strong> You have the right to access the personal information we hold about you.</li>
                    <li><strong>Right to Rectification:</strong> You have the right to request correction of any inaccurate or incomplete personal information.</li>
                    <li><strong>Right to Erasure:</strong> You have the right to request deletion of your personal information, subject to certain exceptions.</li>
                    <li><strong>Right to Restrict Processing:</strong> You have the right to request that we restrict the processing of your personal information.</li>
                    <li><strong>Right to Data Portability:</strong> You have the right to receive your personal information in a structured, commonly used, and machine-readable format.</li>
                    <li><strong>Right to Object:</strong> You have the right to object to the processing of your personal information for direct marketing purposes.</li>
                </ul>
                <h3>7.2. California (CCPA)</h3>
                <ul>
                    <li><strong>Right to Know:</strong> You have the right to know what personal information we collect, use, disclose, and sell.</li>
                    <li><strong>Right to Delete:</strong> You have the right to request deletion of your personal information, subject to certain exceptions.</li>
                    <li><strong>Right to Opt-Out:</strong> You have the right to opt-out of the sale of your personal information.</li>
                    <li><strong>Right to Non-Discrimination:</strong> You have the right not to be discriminated against for exercising your privacy rights under CCPA.</li>
                </ul>
                <h3>7.3. Exercising Your Rights</h3>
                <p>
                    To exercise your privacy rights, please contact us using the information provided in the "Contact Information" section below. We may require you to verify your identity before processing your request.
                </p>
            </section>

            {/* Section 8 */}
            <section>
                <h2>8. Data Security</h2>
                <p>
                    We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the Internet or method of electronic storage is completely secure, and we cannot guarantee the absolute security of your personal information.
                </p>
            </section>

            {/* Section 9 */}
            <section>
                <h2>9. Data Retention</h2>
                <p>
                    We retain your personal information for as long as necessary to fulfill the purposes for which it was collected, including to comply with legal obligations, resolve disputes, and enforce our agreements. When your personal information is no longer required, we will delete or anonymize it in accordance with our data retention policies.
                </p>
            </section>

            {/* Section 10 */}
            <section>
                <h2>10. Children's Privacy</h2>
                <p>
                    e-mall247.com is not intended for use by children under the age of 13, and we do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information promptly.
                </p>
            </section>

            {/* Section 11 */}
            <section>
                <h2>11. Third-Party Links and Services</h2>
                <p>
                    Our platform may contain links to third-party websites and services that are not operated by us. We are not responsible for the privacy practices of these third parties, and we encourage you to review their privacy policies before providing any personal information.
                </p>
            </section>

            {/* Section 12 */}
            <section>
                <h2>12. Cookies and Tracking Technologies</h2>
                <p>
                    We use cookies and similar tracking technologies to collect information about your browsing behavior and improve your experience on our platform. You can manage your cookie preferences through your browser settings. For more information about our use of cookies, please refer to our Cookie Policy.
                </p>
            </section>

            {/* Section 13 */}
            <section>
                <h2>13. Changes to This Privacy Policy</h2>
                <p>
                    We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. We will notify you of any significant changes by posting the updated policy on our platform and updating the "Effective Date" at the top of this policy. Your continued use of e-mall247.com after the changes become effective constitutes your acceptance of the updated policy.
                </p>
            </section>

            {/* Section 14 */}
            <section>
                <h2>14. Contact Information</h2>
                <p>
                    If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:
                </p>
                <p>
                    <strong>KrunchIQ</strong><br />
                    Teaneck NJ 07666 USA<br />
                    Email: <a href="mailto:support@lambdacrunch.com">support@lambdacrunch.com</a><br />
                    Phone: (800) 507-1334
                </p>
            </section>

            {/* Return Button */}
            <div className="return-button-container">
                <button onClick={() => navigate('/')} className="return-button">
                    Return to Home
                </button>
            </div>


        </div>
    );
};

export default Policy;
